import React from "react"
import {useStaticQuery, graphql} from "gatsby"
import styled from "styled-components"
import Markdown from "../components/Markdown.js"

const Hero = styled.section `
	background: #6EA685;
	min-height: 400px;

	article{
		display: flex;
		flex-direction: column;
		padding: 4rem;
	}
`

export default function App() {
	const data = useStaticQuery(graphql `
    query {
      datoCmsHomepage(locale: {eq: "en"}) {
        presentationNode {
      		id
      		childrenMarkdownRemark {
        		html
      		}
    		}
			}
    }
  `)

	return (
			<Hero>
				<Markdown data={data.datoCmsHomepage.presentationNode.childrenMarkdownRemark}/>
			</Hero>)
}
