import React from "react"
import styled from "styled-components"

export default function Markdown(props) {
	const data = props.data[0].html
	return (
		data != null ? (
		<article dangerouslySetInnerHTML={{__html: data}}></article>
			):(
				<p>Humm this document looks empty 😶
					<pre> {
						JSON.stringify(data, null, 4)
					} </pre>
				</p>
			))
	}
